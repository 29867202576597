<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="clients"
      :loading="loading"
      hide-default-footer
      disable-pagination
      @click:row="handleClick"
      sort-by="name"
      class="elevation-1 my-3"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Cases</v-toolbar-title>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.gender="{ item }">
        <span class="titlecase">{{ item.gender }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.titlecase {
  text-transform: capitalize;
}
::v-deep tr {
  cursor: pointer !important;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  mixins: [hasuraService],
  props: {
    user_id: Number,
  },

  data: () => ({
    valid: true,
    req: [(v) => !!v || "Field is required"],
    loading: false,
    headers: [
      { text: "Agent", value: "agent_name" },
      { text: "Name", value: "name" },
      { text: "Gender", value: "gender" },
      { text: "State", value: "state" },
      { text: "Age", value: "age" },
      { text: "Current Annuities", value: "current_annuities" },
      { text: "Calculations", value: "calculations" },
    ],
    clients: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    handleClick(e) {
      this.$router.push(`/client/${e.id}`);
    },
    async initialize() {
      // TODO: read from database
      this.loading = true;
      this.clients = (await this.admin_client()).map((client) => {
        return {
          ...client,
          agent_name: client.user.first_name + " " + client.user.last_name,
          current_annuities: client.client_current_annuities.length,
          calculations: client.calculators.length,
        };
      });
      this.loading = false;
    },
  },
};
</script>
